<template>

  <drawer-form
    ref="drawerForm"
    :title="model.userId>0 ? '编辑':'新增'"
    :model="model"
    :rules="rules"
    :loading="loading"
    @handleSubmit="handleSubmit">

    <a-form-model-item label='用户名' prop="username">
      <a-input v-model="model.username" :disabled="model.userId>0"/>
    </a-form-model-item>

    <a-form-model-item label='登录密码' prop="password">
      <a-input :disabled="!modifyPassword && model.userId>0" v-model="model.password">
        <a-checkbox v-if="model.userId>0" v-model="modifyPassword" slot="addonAfter">修改密码</a-checkbox>
      </a-input>
    </a-form-model-item>

    <a-form-model-item label='邮箱' prop="email">
      <a-input  v-model="model.email" ></a-input>
    </a-form-model-item>

    <a-form-model-item label='手机号' prop="mobile">
      <a-input  v-model="model.mobile" ></a-input>
    </a-form-model-item>

    <a-form-model-item label='角色' prop="roleId">
      <a-select style="width: 100%" v-model="model.roleId">
        <a-select-option v-for="r in roleData" :key="r.roleId">{{r.roleName}}</a-select-option>
      </a-select>
    </a-form-model-item>

    <a-form-model-item label='部门' prop="deptId">
      <a-tree-select
        :allowClear="true"
        :dropdownStyle="{ maxHeight: '220px', overflow: 'auto' }"
        :treeData="deptTreeData"
        v-model="model.deptId"
      />
    </a-form-model-item>

    <a-form-model-item label='性别' prop="ssex">
      <a-radio-group v-model="model.ssex" size="middle">
        <a-radio-button value="0">保密</a-radio-button>
        <a-radio-button value="1">男</a-radio-button>
        <a-radio-button value="2">女</a-radio-button>
      </a-radio-group>
    </a-form-model-item>

    <a-form-model-item label='备注' prop="description">
      <a-input  v-model="model.description" ></a-input>
    </a-form-model-item>

    <a-form-model-item label='状态' prop="status">
      <a-radio-group v-model="model.status" size="middle">
        <a-radio-button value="1">有效</a-radio-button>
        <a-radio-button value="0">锁定</a-radio-button>

        &nbsp;&nbsp;锁定后该账号无法登录
      </a-radio-group>
      &nbsp;&nbsp;
    </a-form-model-item>

  </drawer-form>

</template>
<script>
  import {mapState, mapMutations} from 'vuex'

  const formItemLayout = {
    labelCol: {span: 3},
    wrapperCol: {span: 18}
  }
  export default {
    name: 'UserEdit',
    data() {
      return {
        model:{},
        rules:{
          username: [
            {required: true, message: '必填项'},
            {min: 6, message: '长度不少于6个字符'},
            {max: 50, message: '长度不能超过50个字符'}
          ],
          password: [
            {required: true, message: '必填项'},
            {min: 6, message: '长度不少于6个字符'},
          ],
          roleId: [
            {required: true, message: '必填项'},
          ]
        },
        modifyPassword:false,
        deptTreeData: [],
        roleData: [],
        loading: false,

      }
    },
    computed: {
      ...mapState({
        currentUser: state => state.account.user
      })
    },
    methods: {
      show(model = {ssex:"0",status:"1"}) {
        if(model.password){
          model.password = "******"
        }
        this.model = JSON.parse(JSON.stringify(model))
        this.modifyPassword = this.model.userId == 0

        this.$refs.drawerForm.show()

        this.$get('role').then((r) => {
          this.roleData = r.data.rows
        })
        this.$get('dept').then((r) => {
          this.deptTreeData = r.data.rows.children
        })

      },
      hide(){
        this.$refs.drawerForm.hide()
      },
      ...mapMutations({
        setUser: 'account/setUser'
      }),

      handleSubmit() {
        if(this.model.userId>0){
          this.edit()
        }else{
          this.add()
        }
      },
      add(){
        this.loading = true
        this.$post('user', this.model).then((r) => {
          this.loading = false
          this.$emit('success')
          this.hide()
        }).catch(() => {
          this.loading = false
        })
      },
      edit(){

        this.model.createTime=null
        this.model.modifyTime=null
        this.model.lastLoginTime=null
        this.loading = true
        let model = {...this.model}
        if(!this.modifyPassword){
          model.password=""
        }
        this.$put('user', model).then((r) => {
          this.loading = false
          this.hide()
          this.$emit('success')
          // 如果修改用户就是当前登录用户的话，更新其state
          if (user.username === this.currentUser.username) {
            this.$get(`user/${user.username}`).then((r) => {
              this.setUser(r.data)
            })
          }

        }).catch(() => {
          this.loading = false
        })
      }
    }
  }
</script>
